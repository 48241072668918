.chat-container{
    height: 100vh !important;
  }
 
  .chat-online {
    color: #34ce57
  }
  
  .chat-offline {
    color: #e4606d
  }
  .chat-head-box {
    width: 40vW;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: #F0F0F3;
  }
  .chat-head-scroll{
    height: 90vh;
    overflow-y: auto;
    background-color: #F0F0F3;
  }
  .chat-message-container{
    width: 60vW;
    overflow-y: hidden;
  }
  .chat-head-bgcolor{
    background-color: #F0F0F3;
  }
  .chat-head-selected{
    background-color: rgba(0, 0, 0, 0.08);
  }
  .chat-head-bgcolor:hover{
    background-color: rgba(0, 0, 0, 0.08);
  }
  .chat-messages {
    min-height: 74vh;
    max-height: 78vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .chat-message-body{
    display: flex;
    flex-direction: column;
  }
  .chat-input{
    position:fixed;
    bottom: 5px;
    margin-bottom: 10px;
    background-color: #ffffff;
}
  @media (max-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    .chat-messages {
      min-height: 65vh;
    max-height: 65vh;
    }
    .chat-input{
        width: 100vw;
        position:fixed;
        bottom: 5px;
        margin-bottom: 10px;
        background-color: #ffffff;
    }
    
  }
  @media (max-width:480px)  { /* smartphones, Android phones, landscape iPhone */ 
    .chat-messages {
      min-height: 65vh;
      max-height: 65vh;
    }
    .chat-input{
        width: 100vw;
        position:fixed;
        bottom: 5px;
        margin-bottom: 10px;
    }
  }

  .horizontal-scroll{
    min-width: 80vw;
    overflow-y: auto
  }
  .chat-card {
    min-width: unset !important;
  }
  
  .chat-message-error {
    display: flex;
    flex-shrink: 0;
    font-weight: 600;
  }
  @media only screen and (max-width: 767px){
  .text-right {
      text-align: right !important;
  }}
  .chat-message-left,
  .chat-message-right {
    display: flex;
    flex-shrink: 0;
  }
  
  .chat-message-left {
    margin-right: auto;
    min-width: 20%;
  }
  .chat-round{
    border-radius:1.25rem !important;
  }
  .chat-bg-left{
    background-color: #dee4e9;
    color:#000000;
  }
  .chat-bg-right{ 
    color:#fff3f5;
    background-color: #002B4B;
  }
  .chat-bg-right-notsent{
    background-color: #002B4B !important;
  }
  .chat-bg-right-discard{
    background-color: rgba(255, 105, 95, 0.5)  !important;
    filter: blur(0.2px);
  }
  .chat-textarea{
     .message-wrapper {
      display: flex;
      border: 1px solid #F0F0F3;
      border-radius: 20px 0px 0 20px;
      background-color: #F0F0F3;
      padding: 5px 24px 0 24px; /* the container will keep the padding untouched */
      flex: 1;
      min-height: 40px; /* prevent height collapsing when there is no text */
      align-content: right;
      outline: none;
      overflow: hidden;
    }
    .message-text {
      flex: 1;
      min-height: 1em; /* prevent height collapsing when there is no text */
      align-content: right;
      outline: none;
      overflow: hidden;
    }
    [contenteditable] {padding:12px; background:#eee; }
  
    [data-placeholder]:empty:before{
      content: attr(data-placeholder);
      color: #888;
      font-style: italic;
    }
  }
  .notification-dot-head {
     background-color: #FF695F;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    text-align: center;
    color: #FFFFFF;
    margin-left: -15px;
  }
  
  .notification-dot-big {
    background-color: #FF695F;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    text-align: center;
    color: #FFFFFF;
  }
  
  @keyframes notification-dot-big {  
  from { opacity:1; }
  to { opacity: 0.6; }
  }
  .chat-message-right {
    flex-direction: row-reverse;
    margin-left: auto;
    min-width: 20%;
  }